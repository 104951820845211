import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {AbstractTemplateDirective} from "./base-directive/abstract-template-directive";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ScreenSize} from "./constants/ScreenSize";

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tablet-mobile-template]'
})
export class TabletMobileTemplateDirective extends AbstractTemplateDirective {

  constructor(_templateRef: TemplateRef<never>,
              _container: ViewContainerRef,
              _breakpointObserver: BreakpointObserver) {
    super(_templateRef, _container, _breakpointObserver, ScreenSize.TABLET, ScreenSize.MOBILE);
  }
}
