import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {MetaConfig} from "./meta";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  renderer : Renderer2

  constructor(private meta: Meta ,private titleService: Title ,
              private rendererF : RendererFactory2 ,@Inject(DOCUMENT) private dom :any ) {
    this.renderer = rendererF.createRenderer(null, null);

  }

  private generateTags(config: MetaConfig) {
    this.meta.updateTag({property: "og:type", content: "website"});
    this.meta.updateTag({property:'og:image', content: `${environment.hostUrl}/assets/images/consulting.png`});
    this.meta.updateTag({property: "og:country-name", content: "France"});
    this.meta.updateTag({property: "og:site_name", content: "Yellow It"});
    this.meta.updateTag({property: "og:url", content: config.url});
    this.meta.updateTag({property: "og:title", content: config.metaTitle});
    this.meta.updateTag({property: "og:description", content: config.description});
    this.meta.updateTag({name: "title", content: config.title});
    this.meta.updateTag({name: "description", content: config.description});
  }
   private  setTitle(title : string ):void {
    this.titleService.setTitle(title);
  }
  updatePage(config : MetaConfig , title ?: string):void {
    this.generateTags(config) ;
    if(title){
      this.setTitle(title);

    }
  }


  createCanonicalURL() {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', environment.hostUrl );
  }
  updateCanonicalUrl (url: string ) :void {
    const linkElement :HTMLLinkElement  = this.dom.head.querySelector(`link[rel='canonical']`)
    linkElement.removeAttribute('href')
    linkElement.setAttribute('href', environment.hostUrl + url);

  }
}
