import { Component, HostBinding, Input} from '@angular/core';



export interface CustomButtonSetting {
  uppercase?: boolean;
  color :string
  text : string
  backGroundColor : string
  border : string
  initialBorder : string
  link? :string
  initialColor :string,
  fragment? : string,
  left? : string
  width ? : string
}
@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrl: './custom-button.component.scss'
})
export class CustomButtonComponent {
  @Input() setting? : CustomButtonSetting;

  constructor() {}

  @HostBinding('attr.style')
  public get cssVars() {
    return `
    --switch-border: 1px solid ${this.setting?.border} ;
    --switch-backgroundColor: ${this.setting?.backGroundColor};
    --switch-color: ${this.setting?.color};
    --switch-initalBorder: 1px solid ${this.setting?.initialBorder};
    --switch-left : ${this.setting?.left ? this.setting.left : '10'}%;
  `;
  }
}
