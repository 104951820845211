import {Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {DomSanitizer, SafeValue} from "@angular/platform-browser";



export interface SubBlockInformationsInterface {
  title : string,
  subtitle: string
  desc: string
  color ?: string
  titleColor ?:string
  img ?:string,
  isUnique?: boolean;
  psize? : string,
  bottom?: string
  titlePadding? :string
}
@Component({
  selector: 'app-sub-block-informations',
  templateUrl: './sub-block-informations.component.html',
  styleUrl: './sub-block-informations.component.scss'
})
export class SubBlockInformationsComponent implements OnInit{
  @Input() config! : SubBlockInformationsInterface;
  safeDesc! : SafeValue;
  constructor(private renderer :Renderer2 , private  el :ElementRef ,   @Inject(PLATFORM_ID) private platformId: Object , private sanitizer: DomSanitizer) {

  }


  onEnterView(wrapper:any) {
    const element =this.el.nativeElement.querySelector(wrapper);
    if( isPlatformBrowser(this.platformId) && element ){
      this.renderer.addClass(element, 'fadeIn') ;
    }
  }

  ngOnInit(): void {
    this.safeDesc = this.sanitizer.bypassSecurityTrustHtml(this.config.desc);
  }
}
