import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import {RouterModule} from "@angular/router";
import {TabletMobileTemplateDirective} from "../services/directives/tablet-mobile-template.directive";
import {WebTemplateDirective} from "../services/directives/web-template.directive";
import { SubBlockInformationsComponent } from './sub-block-informations/sub-block-informations.component';
import {AppearDirective} from "./apprear.directive";



@NgModule({
  declarations: [
    CustomButtonComponent,
    SubBlockInformationsComponent,
    AppearDirective],
  imports: [
    CommonModule,
    RouterModule,
    TabletMobileTemplateDirective,
    WebTemplateDirective,
    NgOptimizedImage,
  ],
    exports: [
        CustomButtonComponent,
        TabletMobileTemplateDirective,
        WebTemplateDirective,
        CommonModule,
        SubBlockInformationsComponent,
         AppearDirective
    ]
  ,
})
export class SharedModule { }
