import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";
import {AbstractTemplateDirective} from "./base-directive/abstract-template-directive";
import {ScreenSize} from "./constants/ScreenSize";

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[web-template]'
})
export class WebTemplateDirective extends AbstractTemplateDirective {

  constructor(_templateRef: TemplateRef<never>,
              _container: ViewContainerRef,
              _breakpointObserver: BreakpointObserver) {
    super(_templateRef, _container, _breakpointObserver, ScreenSize.WEB);
  }
}
