<div (appear)="onEnterView('.animation')">
  @if(config) {
    <div   *tablet-mobile-template class="wrapper" >
      @if(config.isUnique){
        <div style="font-size: 24px" class="Cabin title uppercase" [ngStyle]="{'color' : config && config.titleColor ? config.titleColor:'white'}">Une</div>
      }
      <div class="Cabin title uppercase" [ngStyle]="{'color' : config && config.titleColor ? config.titleColor:'white' , 'padding-left' : config.titlePadding }" >

        @if (config.title) {
          {{config.title}} <br>
        }
        @if (config.subtitle){
          <span [ngStyle]="{'color' : config && config.color ? config.color:'white'}" class="subTitle">{{config.subtitle}}</span> <br>
        }
      </div>
      <div class="animation">
         <span class="icon">
                   @if(config.img ) {
                     <img [src]="config.img" alt="icon exponential"  height="93" width="100">
                   } @else {
                     <img  ngSrc="assets/images/shared/expo.png" alt="icon exponential" height="110" width="61">
                   }
            </span>
        <p  [ngStyle]="{'color' : config && config.color ? config.color:'white'}" [innerHTML]="safeDesc"></p>
      </div>
    </div>
  }
</div>
  <div  (appear)="onEnterView('.animation_web')">

  @if(config) {
    <div *web-template  class="wrapper-web" >
      @if(config.isUnique){
        <div style="font-size: 48px" class="Cabin title uppercase" [ngStyle]="{'color' : config && config.titleColor ? config.titleColor:'white'}">Une</div>
      }
      <div id="title" class="Cabin title uppercase" [ngStyle]="{'color' : config && config.titleColor ? config.titleColor:'white'}">
        @if (config.title) {
          {{config.title}} <br>
        }
         @if (config.subtitle){

           <span [ngStyle]="{'color' : config && config.color ? config.color:'white'}" class="subTitle" >  {{config.subtitle}}</span> <br>
         }
      </div>
      <div class="animation_web" >
         <span class="icon" >
              @if(config.img ) {
                <img  [ngSrc]="config.img" alt="icon exponential" width="200" height="94">
              } @else {
                <img width="250"  height="85" src="assets/images/shared/expo_white_web.png" alt="icon exponential" >
              }
      </span>
        <p  [ngStyle]="{'color' : config && config.color ? config.color:'white' , 'font-size' : config.psize ? config.psize : '2rem', 'bottom': config.bottom?config.bottom : '17px'}" [innerHTML]="safeDesc"></p>
      </div>

    </div>
  }


</div>
