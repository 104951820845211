@if(setting) {
  <div class="cta_custom_button" >
      @if (setting.link){
        @if(!setting.fragment){
          <a [ngStyle]="{'color' : setting ? setting.initialColor : 'inherit' , 'min-width' : setting.width? setting.width : '170px'}" id="link"
             [href]="setting.link"
             [routerLink]="setting.link"
             class="cta"  >
            <span [ngClass]="{'uppercase' : setting.uppercase}" >{{ setting ? setting.text :''}}</span>
          </a>
        }

        @else{

          <a  [ngStyle]="{'color' : setting ? setting.initialColor : 'inherit' ,  'min-width' : setting.width? setting.width : '170px'}" id="link"
              [href]="setting.link"
              [routerLink]="setting.link"
              [fragment]="setting.fragment"
              class="cta"  >
            <span [ngClass]="{'uppercase' : setting.uppercase}">{{ setting ? setting.text :''}}</span>
          </a>
        }
      } @else {
        <button type="button" [ngStyle]="{'color' : setting ? setting.initialColor : 'inherit' , 'min-width' : setting.width? setting.width : '170px'}" id="link"
            class="cta pointer button"  >
          <span [ngClass]="{'uppercase' : setting.uppercase}">{{ setting ? setting.text :''}}</span>
        </button>
      }
  </div>
}
