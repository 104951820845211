import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ScreenSize } from "../constants/ScreenSize";
import { map, startWith, takeUntil, shareReplay } from "rxjs/operators";
import {Subject, Observable, combineLatest} from "rxjs";

@Directive()
export abstract class AbstractTemplateDirective implements OnInit, OnDestroy {
  private static allScreenSizes: ScreenSize[] = Object.values(ScreenSize);
  private static breakpoints$: Observable<BreakpointState>;
  private readonly sizesToShowOn: ScreenSize[];
  private isVisible: boolean | undefined;
  private _unsubscribe: Subject<void>;

  protected constructor(
    private templateRef: TemplateRef<never>,
    private container: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
    ...screenSizesToShowOn: ScreenSize[]
  ) {
    this.sizesToShowOn = screenSizesToShowOn;
    this._unsubscribe = new Subject<void>();
    // Create a shared observable for breakpoints if not already created
    if (!AbstractTemplateDirective.breakpoints$) {
      AbstractTemplateDirective.breakpoints$ = this.createBreakpointsObservable();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  ngOnInit(): void {
    this.setupTemplate(this.sizesToShowOn);
  }

  private createBreakpointsObservable(): Observable<BreakpointState> {
    const breakpoints = AbstractTemplateDirective.allScreenSizes.map(size => this.breakpointObserver.observe(size));
    return combineLatest(breakpoints).pipe(
      map(states => {
        const matchedBreakpoints = states.reduce((result, state) => {
          return { ...result, ...state.breakpoints };
        }, {} as { [key: string]: boolean });
        return { breakpoints: matchedBreakpoints } as BreakpointState;
      }),
      shareReplay(1) // Share the observable and replay the latest emitted value
    );
  }

  protected setupTemplate(screenSizes: ScreenSize | ScreenSize[]): void {
    this.showOnSizes(screenSizes);
    this.hideOnSizes(this.getSizesToHideOn());
  }

  protected hideOnSizes(sizes: ScreenSize | ScreenSize[]): void {
    AbstractTemplateDirective.breakpoints$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((state: BreakpointState) => {
        if (this.isVisible && this.matchesSizes(state, sizes)) this.hide();
      });
  }

  protected showOnSizes(sizes: ScreenSize | ScreenSize[]): void {
    AbstractTemplateDirective.breakpoints$
      .pipe(
        takeUntil(this._unsubscribe),
        map((state: BreakpointState) => this.matchesSizes(state, sizes)),
        startWith(this.isDefaultScreenSize())
      )
      .subscribe((isMatch: boolean) => {
        if (!this.isVisible && isMatch) this.show();
      });
  }

  private matchesSizes(state: BreakpointState, sizes: ScreenSize | ScreenSize[]): boolean {
    const sizesArray = Array.isArray(sizes) ? sizes : [sizes];
    return sizesArray.some(size => state.breakpoints[size]);
  }

  protected show(): void {
    this.container.createEmbeddedView(this.templateRef);
    this.isVisible = true;
  }

  protected hide(): void {
    this.container.clear();
    this.isVisible = false;
  }

  private isDefaultScreenSize(): boolean {
    return this.sizesToShowOn.includes(ScreenSize.DEFAULT);
  }

  private getSizesToHideOn(): ScreenSize[] {
    return AbstractTemplateDirective.allScreenSizes.filter(size => !this.sizesToShowOn.includes(size));
  }
}
